<template>
  <div>
    <v-select
      v-model="value.periodName"
      :items="periodSelect"
      label="Zeitraum"
      return-object>
    </v-select>
    <v-select
      v-model="value.mode"
      :items="modeSelect"
      label="Modus"
      return-object>
    </v-select>
    <v-select
      v-if="value.mode.value == 'compare'"
      v-model="value.compareTo"
      :items="compareSelect"
      label="Vergleichen mit"
      return-object>
    </v-select>
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
    ></v-switch>
  </div>
</template>

<script>

export default {
  components: {  },
  data: () => ({
    periodSelect: [{
      text: 'Zeitraum 1',
      value: 'period1'
    },{
      text: 'Zeitraum 2',
      value: 'period2'
    },{
      text: 'Zeitraum 3',
      value: 'period3'
    },{
      text: 'Zeitraum 4',
      value: 'period4'
    },{
      text: 'Zeitraum 5',
      value: 'period5'
    }],
    modeSelect: [{
      text: 'Eingabemaske für diesen Zeitraum anzeigen',
      value: 'input'
    },{
      text: 'Energieverbrauch pro GTZ anzeigen',
      value: 'energyPerGTZ'
    },{
      text: 'Energieverbrauch bei unterschiedlichen Innentemperaturen anzeigen',
      value: 'consumptionVariants'
    },{
      text: 'Mit anderem Zeitraum vergleichen',
      value: 'compare'
    }],
    compareSelect: [{
      text: 'Zeitraum 1',
      value: 'period1'
    },{
      text: 'Zeitraum 2',
      value: 'period2'
    },{
      text: 'Zeitraum 3',
      value: 'period3'
    },{
      text: 'Zeitraum 4',
      value: 'period4'
    },{
      text: 'Zeitraum 5',
      value: 'period5'
    }]
  }),
  props: [
    'value'
  ],
  watch: {
    value: {
      handler() {
        this.$emit('input', this.value)
      },
      deep: true
    }
  }
  
}

</script>
<style>

</style>
