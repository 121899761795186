<template>
   <v-card elevation="6" class="fill-height overflow-auto">
    <v-card-title>
      Station auswählen
    </v-card-title>
    <v-card-text >

      <v-autocomplete
        :items="alleOrte"
        :filter="filterOrte"
        @change="checkInput"
        label="Ihr Wohnort"
        v-model="value.weatherStation.selectedOrt"
        hide-selected
        clearable
        auto-select-first
        no-data-text="kein Ort gefunden"
        hint="Geben Sie hier die PLZ oder den Ort ein"
        persistent-hint
      >
        <template v-slot:selection="data">
          {{data.item.plz}} {{data.item.ort}}
        </template>
        <template v-slot:item="data">
          {{data.item.plz}} {{data.item.ort}}
        </template>

      </v-autocomplete>

        <v-subheader dense style="height: 30px" class="pa-0">Nächstgelegene Stationen</v-subheader>
        <v-list
          subheader
          dense
        >
        <v-radio-group v-model="value.weatherStation.selectedStation" @change="emitData()">
          <v-list-item v-for="(station, i) in value.weatherStation.nearestStations"
          :key="i" class="pa-0">
            <v-list-item-action>
              <v-radio  :value="station"></v-radio>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ station.name }}</v-list-item-title>
              <v-list-item-subtitle>Höhe {{station.height}}m, {{station.distance.toFixed(1)}} km von {{value.weatherStation.selectedOrt ? value.weatherStation.selectedOrt.plz : ''}} {{value.weatherStation.selectedOrt ? value.weatherStation.selectedOrt.ort : ''}} entfernt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-radio-group>
        </v-list>
      
    </v-card-text>
    <v-overlay :value="showOverlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
  import alleOrte from "./../alleOrte.json"
  import axios from 'axios'
  export default {
    data: () => ({
      alleOrte: alleOrte,
      filteredOrte: null,
      nearestStations: []
    }),
    props: [
      'editmode',
      'value',
      'showOverlay'
    ],
    computed: {
      
    },
    methods: {
      checkInput(data) {
        if (data) {
          this.getNearestStations()
        }
      },
      getNearestStations() {
        this.showOverlay = true
        if (this.value.weatherStation.selectedOrt) {
          let coordinates = parseFloat(this.value.weatherStation.selectedOrt.lat.replace(/,/g, '.')) + ',' + parseFloat(this.value.weatherStation.selectedOrt.long.replace(/,/g, '.'))
          let payload = { 
            provider: 'dwd', 
            network: 'observation',
            parameter: 'kl',
            resolution: 'daily',
            coordinates: coordinates,
            period: 'recent',
            tidy: true,
            skip_empty: true,
            rank: 3
          }
          axios.get('https://klima.ruckzuk.de/restapi/stations', { params: payload }).then(response => (
            this.value.weatherStation.nearestStations = response.data.data,
            this.value.weatherStation.selectedStation = response.data.data[0],
            this.emitData()
          ))
        }
      },
      filterOrte (item, queryText) {
        if (item.plz != undefined) {
          if (item.plz.startsWith(queryText) || item.ort.toLowerCase().startsWith(queryText.toLowerCase())) {
            return item
          }
        }
      },
      emitData() {
        this.showOverlay = true
        localStorage.setItem('weatherStation', JSON.stringify({
          weatherStation: {
            selectedStation: this.value.weatherStation.selectedStation,
            selectedOrt: this.value.weatherStation.selectedOrt,
            nearestStations: this.value.weatherStation.nearestStations
          }
        }))
        this.$emit('input', this.value)
      }
    }
  }
</script>
<style>

</style>
