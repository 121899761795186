<template>
  <div >
    <h4 class="text-center">{{this.widgetSettings.sliderConfig.text}}</h4>
    <v-slider
      v-if="this.widgetSettings.sliderConfig.value == 'heatingLimitTemperature'"
      :value="boardSettings.heatingDegreeSettings.heatingLimitTemperature"
      :tick-labels="ticks"
      :max="max"
      :min="min"
      step="1"
      ticks="always"
      tick-size="4"
      @change="setHeatingLimit"
    ></v-slider>
    <v-slider
      v-else-if="this.widgetSettings.sliderConfig.value == 'indoorTemperature'"
      :value="boardSettings.heatingDegreeSettings.indoorTemperature"
      :tick-labels="ticks"
      :max="max"
      :min="min"
      step="1"
      ticks="always"
      tick-size="4"
      @change="setIndoorTemperature"
    ></v-slider>
      <v-slider
      v-else
      :value="sliderValue"
      :tick-labels="ticks"
      :max="max"
      :min="min"
      step="1"
      ticks="always"
      tick-size="4"
      @change="setValue"
      
    ></v-slider>
  </div>

</template>

<script>
  import { DateTime } from "luxon"
  export default {
    data: () => ({
    }),
    props: [
      'selectedDate',
      'widgetSettings',
      'config',
      'boardSettings',
      'editmode'
    ],
    computed: {
      startYear() {
        return DateTime.fromISO(this.config.startDateClimateData).toFormat('yyyy')
      },
      endYear() {
        return DateTime.now().toFormat('yyyy')
      },
      sliderConfig() {
        return this.widgetSettings.sliderConfig.value
      },  
      sliderValue() {
        if (this.sliderConfig == 'years') {
          return DateTime.fromISO(this.selectedDate).toFormat('yyyy')
        }
        else if (this.sliderConfig == 'months') {
          return DateTime.fromISO(this.selectedDate).toFormat('MM')
        }
        else if (this.sliderConfig == 'days') {
          return DateTime.fromISO(this.selectedDate).toFormat('dd')
        } else {
          return null
        }
      },
      ticks() {
        var ticks = []
        if (this.sliderConfig == 'years') {
          for (let i = this.startYear; i <= this.endYear; i++) {
            ticks.push(i)
          }
          return ticks
        }
        else if (this.sliderConfig == 'months') {
          return ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
        }
        else if (this.sliderConfig == 'days') {
          for (let i = 1; i <= this.daysInMonth; i++) {
            ticks.push(i)
          }
          return ticks
        } else if (this.sliderConfig == 'heatingLimitTemperature') {
          for (let i = this.config.heatingDegreeSettings.heatingLimitTemperatureRange[0]; i <= this.config.heatingDegreeSettings.heatingLimitTemperatureRange[1]; i++) {
            ticks.push(i + '°')
          }
          return ticks
        } else {
          for (let i = this.config.heatingDegreeSettings.indoorTemperatureRange[0]; i <= this.config.heatingDegreeSettings.indoorTemperatureRange[1]; i++) {
            ticks.push(i + '°')
          }
          return ticks
        }
      },
      min() {
        if (this.sliderConfig == 'years') {
          return this.startYear
        } 
        else if (this.sliderConfig == 'heatingLimitTemperature') {
          return this.config.heatingDegreeSettings.heatingLimitTemperatureRange[0]
        }
        else if (this.sliderConfig == 'indoorTemperature') {
          return this.config.heatingDegreeSettings.indoorTemperatureRange[0]
        }
        else {
          return 1
        }
      },
      max() {
        if (this.sliderConfig == 'years') {
          return this.endYear
        }
        else if (this.sliderConfig == 'months') {
          return 12
        }
        else if (this.sliderConfig == 'days') {
          return this.daysInMonth
        } 
        else if (this.sliderConfig == 'heatingLimitTemperature') {
          return this.config.heatingDegreeSettings.heatingLimitTemperatureRange[1]
        } 
        else {
          return this.config.heatingDegreeSettings.indoorTemperatureRange[1]
          
        }
      },
      daysInMonth() {
        return new Date(this.currentYear, this.currentMonth, 0).getDate()
      },
      currentYear() {
        return DateTime.fromISO(this.selectedDate).toFormat('yyyy')
      },
      currentMonth() {
        return DateTime.fromISO(this.selectedDate).toFormat('MM')
      },
      currentDay() {
        return DateTime.fromISO(this.selectedDate).toFormat('dd')
      }
      
    },
    methods: {
      setValue(val) {
        var newSelectedDate
        if (this.sliderConfig == 'years') {
          newSelectedDate = DateTime.now().set({
            year: val,
            month: this.currentMonth,
            day: this.currentDay
          })
        }
        else if (this.sliderConfig == 'months') {
          newSelectedDate = DateTime.now().set({
            year: this.currentYear,
            month: val,
            day: this.currentDay
          })
        }
        else if (this.sliderConfig == 'days') {
          newSelectedDate = DateTime.now().set({
            year: this.currentYear,
            month: this.currentMonth,
            day: val
          })
        }
        this.$emit('setSelectedDate', newSelectedDate)
      },
      setHeatingLimit(val) {
        console.log(this.boardSettings)
        var heatingDegreeSettings = this.boardSettings.heatingDegreeSettings
        heatingDegreeSettings.heatingLimitTemperature = val
        this.$emit('setHeatingDegreeSettings', heatingDegreeSettings)
      },
      setIndoorTemperature(val) {
        var heatingDegreeSettings = this.boardSettings.heatingDegreeSettings
        heatingDegreeSettings.indoorTemperature = val
        this.$emit('setHeatingDegreeSettings', heatingDegreeSettings)
      }
    }
    
  }

</script>
<style>
.v-slider__tick-label {
  font-size: 0.875rem;
}
</style>
