<template>
   <v-card>
      <v-card-title>
        CSV-Export
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="resolution"
          :items="resolutionSelect"
          label="Auflösung"
          return-object
        ></v-select>
        Parameter:
        <v-checkbox
          v-for="(item, i) in paramsSelect"
          :key="i"
          v-model="parameter"
          :label="item.text"
          :value="item"
          multiple
          dense
        ></v-checkbox>
        Zeitraum:
        <v-menu
          v-model="datePickerStart"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateStartGerman"
              label="Von"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateStart"
            @change="datePickerStart = false"
            no-title
            type="month"
            locale = "de-de"
            :min="startDateClimateData"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="datePickerEnd"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateEndGerman"
              label="Bis"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateEnd"
            @change="datePickerEnd = false"
            no-title
            type="month"
            locale = "de-de"
            :min="dateStart"
          ></v-date-picker>
        </v-menu>
        <v-btn 
          color="primary"
          @click="exportData()"
        >
          Daten herunterladen
        </v-btn>
      </v-card-text>
    </v-card>
</template>

<script>
  import { DateTime } from "luxon"

  export default {
    name: 'Datatable',

    data: () => ({
      parameter: [{
        text: 'Durchschnittstemperatur', 
        value: 'temperature_air_mean_200',
        unit: '°C'
      }],
      resolution: {
        text: 'Tage',
        value: 'day'
      },
      datePickerStart: false,
      datePickerEnd: false,
      dateStart: DateTime.now().startOf('year').toFormat('yyyy-MM-dd'),
      dateEnd: DateTime.now().endOf('year').toFormat('yyyy-MM-dd')
    }),
    props: [
      'climateDataDaily',
      'climateDataMonthly',
      'climateDataYearly',
      'selectedStation',
      'startDate',
      'startDateClimateData',
      'boardSettings'
    ],
    computed: {
      dateStartGerman: function() {
        return DateTime.fromISO(this.dateStart).startOf('month').toFormat('dd.MM.yyyy')
      },
      dateEndGerman: function() {
        return DateTime.fromISO(this.dateEnd).endOf('month').toFormat('dd.MM.yyyy')
      },
      resolutionSelect() {
        var resolutionSelect = []
        resolutionSelect.push({
          text: 'Tage',
          value: 'day'
        },{
          text: 'Monate',
          value: 'month'
        },{
          text: 'Jahre',
          value: 'year'
        })
        if (this.boardSettings.period1.period[0]) {
          resolutionSelect.push({
            text: this.boardSettings.period1.name,
            value: 'period1'
          })
        }
        if (this.boardSettings.period2.period[0]) {
          resolutionSelect.push({
            text: this.boardSettings.period2.name,
            value: 'period2'
          })
        }
        if (this.boardSettings.period3.period[0]) {
          resolutionSelect.push({
            text: this.boardSettings.period3.name,
            value: 'period3'
          })
        }
        if (this.boardSettings.period4.period[0]) {
          resolutionSelect.push({
            text: this.boardSettings.period4.name,
            value: 'period4'
          })
        }
        if (this.boardSettings.period5.period[0]) {
          resolutionSelect.push({
            text: this.boardSettings.period5.name,
            value: 'period5'
          })
        }
     
        return resolutionSelect
      },
      paramsSelect() {
        var paramsSelect = [{
          text: 'Durchschnittstemperatur', 
          value: 'temperature_air_mean_200',
          unit: '°C'
        },{
          text: 'Höchsttemperatur',
          value: 'temperature_air_max_200',
          unit: '°C'
        },{
          text: 'Tiefsttemperatur',
          value: 'temperature_air_min_200',
          unit: '°C'
        },{
          text: 'Niederschlag',
          value: 'precipitation_height',
          unit: 'l'
        },{
          text: 'Sonnenstunden',
          value: 'sunshine_duration',
          unit: 'h'
        }]
        if (this.resolution.value.startsWith('period')) {
          paramsSelect.push({
            text: 'Gradtagzahl',
            value: 'heating_degree_' + this.resolution.value,
            unit: 'GTZ'
          },{
            text: 'Energieverbrauch',
            value: 'energy_consumption_' + this.resolution.value,
            unit: 'kWh'
          })
        } else {
          paramsSelect.push({
            text: 'Gradtagzahl',
            value: 'heating_degree',
            unit: 'GTZ'
          })
        }
        return paramsSelect
      }
    },
    watch: {
      resolution() {
        if (this.resolution.value.startsWith('period')) {
          this.dateStart = this.boardSettings[this.resolution.value].period[0]
          this.dateEnd = this.boardSettings[this.resolution.value].period[1]
        }
      }
    },
    methods: {
      getHeaders: function() {
        var headers = { date: 'Datum'}
        this.parameter.forEach(function(parameter) {
          headers[parameter.value] = parameter.text
        })
        return headers
      },
      formatDate: function(date) {
        var returndate = DateTime.fromISO(date).toFormat('dd.MM.yyyy')
        if (this.resolution.value == 'month') {
          returndate = DateTime.fromISO(date).toFormat('MM/yyyy')
        }
        if (this.resolution.value == 'year') {
          returndate = DateTime.fromISO(date).toFormat('yyyy')
        }
        return returndate
      },
      roundValue: function(value) {
        return  Math.round(value * 10) / 10
      },
      addUnit: function(item) {
        for (const [key, value] of Object.entries(item)) {
          if (key == 'date') {
              item[key] = DateTime.fromISO(value).toFormat('dd.MM.yyyy')
          } else {
            item[key] = Math.round(value * 10) / 10
          }
        }
        return item
      },
      exportData: function() {
        var climateData = this.climateDataDaily
        var begin = DateTime.fromISO(this.dateStart).startOf('month').toFormat('yyyy-MM-dd')
        var end = DateTime.fromISO(this.dateEnd).endOf('month').toFormat('yyyy-MM-dd')
        if (this.resolution.value == 'month') {
          climateData = this.climateDataMonthly
          begin = DateTime.fromISO(this.dateStart).startOf('month').toFormat('yyyy-MM')
          end = DateTime.fromISO(this.dateEnd).endOf('month').toFormat('yyyy-MM')
        }
        if (this.resolution.value == 'year') {
          climateData = this.climateDataYearly
          begin = DateTime.fromISO(this.dateStart).startOf('year').toFormat('yyyy')
          end = DateTime.fromISO(this.dateEnd).endOf('year').toFormat('yyyy')
        }
                
        var exportClimateData = []
        climateData.forEach(function (item) {
          if (item.date >= begin && item.date <= end) {
            var newItem = {}
            newItem.date = DateTime.fromISO(item.date).toFormat('dd.MM.yyyy')
            if (this.resolution.value == 'month') {
              newItem.date = DateTime.fromISO(item.date).toFormat('MM/yyyy')
            }
            if (this.resolution.value == 'year') {
              newItem.date = DateTime.fromISO(item.date).toFormat('yyyy')
            }
            this.parameter.forEach(function(param) {
              var rounded = Math.round(item[param.value] * 10) / 10
              newItem[param.value] = rounded.toString().replace(".", ",")
            }, this)
            exportClimateData.push(newItem)
          }
        }, this)
        
        var headers = this.getHeaders()
        var title = 'Station ' + this.selectedStation.name + ' vom ' + this.dateStartGerman + ' bis ' + this.dateEndGerman
        this.exportCSVFile(headers, exportClimateData, title)
      },
      convertToCSV: function (objArray) {
          var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
          var str = ''

          for (var i = 0; i < array.length; i++) {
              var line = ''
              for (var index in array[i]) {
                  if (line != '') line += ';'

                  line += array[i][index]
              }

              str += line + '\r\n'
          }
          return str
      },
      exportCSVFile: function(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);
        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
      }
    }
  }
</script>
<style>
.v-color-picker__swatches > div {

    justify-content: left;
    padding: 0;
}
</style>
