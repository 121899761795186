var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-select',{attrs:{"items":_vm.resolutionSelect,"label":"Auflösung","return-object":""},model:{value:(_vm.value.resolution),callback:function ($$v) {_vm.$set(_vm.value, "resolution", $$v)},expression:"value.resolution"}}),_c('v-expansion-panels',{attrs:{"focusable":"","mandatory":""}},_vm._l((_vm.value.charts),function(chart,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(chart.parameter.text)+" ")]),_c('v-expansion-panel-content',{staticClass:"pt-3"},[_c('v-select',{attrs:{"items":_vm.paramsSelect,"label":"Parameter","return-object":""},model:{value:(chart.parameter),callback:function ($$v) {_vm.$set(chart, "parameter", $$v)},expression:"chart.parameter"}}),_c('v-select',{attrs:{"items":_vm.chartSelect,"label":"Diagramm","return-object":""},model:{value:(chart.chart),callback:function ($$v) {_vm.$set(chart, "chart", $$v)},expression:"chart.chart"}}),_c('v-select',{attrs:{"items":[{
            text: 'Skala links anzeigen',
            value: 'left'
          },{
            text: 'Skala rechts anzeigen',
            value: 'right'
          },{
            text: 'Keine Skala anzeigen',
            value: 'none'
          }],"label":"Skala","return-object":""},model:{value:(chart.scale),callback:function ($$v) {_vm.$set(chart, "scale", $$v)},expression:"chart.scale"}}),_c('div',{staticClass:"v-label theme--light d-flex align-center"},[_c('v-swatches',{staticClass:"mr-2",attrs:{"popover-x":"right","swatches":"text-advanced","trigger-style":{ width: '40px', height: '26px' }},model:{value:(chart.color),callback:function ($$v) {_vm.$set(chart, "color", $$v)},expression:"chart.color"}}),_vm._v(" Farbe ")],1),((_vm.value.resolution.value == 'day' || _vm.value.resolution.value == 'month') && i > 0)?_c('v-switch',{attrs:{"label":"Vorjahreswerte anzeigen","hide-details":""},model:{value:(chart.lastYear),callback:function ($$v) {_vm.$set(chart, "lastYear", $$v)},expression:"chart.lastYear"}}):_vm._e(),_c('v-switch',{attrs:{"label":"Werte in Chart anzeigen","hide-details":""},model:{value:(chart.showLabel),callback:function ($$v) {_vm.$set(chart, "showLabel", $$v)},expression:"chart.showLabel"}}),_c('div',{staticClass:"d-flex justify-end mt-3"},[_c('v-spacer'),(i > 0)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteChart(i)}}},[_c('v-icon',[_vm._v(" mdi-minus-circle ")])],1):_vm._e()],1)],1)],1)}),1),_c('div',{staticClass:"d-flex justify-end mt-3"},[_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.addChart()}}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1)],1),_c('v-switch',{staticClass:"mb-4",attrs:{"label":"Im Rahmen anzeigen","hide-details":""},model:{value:(_vm.value.showFrame),callback:function ($$v) {_vm.$set(_vm.value, "showFrame", $$v)},expression:"value.showFrame"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }