<template>
  <v-card   
    class="d-flex flex-column fill-height overflow-hidden" 
    @click="setSelectedWidget()" 
    :class="getCssClasses()" 
    :elevation="hasFrame ? 3 : 0"
    :color="hasFrame ? 'white' : 'transparent'"
    :ripple="false"
  >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  components: {
    
  },
  data: () => ({
    
  }),
  props: [
    'id',
    'editmode',
    'widgetSettings',
    'selectedWidget',
    'widget'
  ],
  mounted: function() {
  },
  computed: {
    isSelected() {
      if (this.selectedWidget && this.editmode) {
        return this.selectedWidget.id == this.id ? true : false
      } else {
        return false
      }

    },
    hasFrame() {
      if (this.widgetSettings) {
        if (this.widgetSettings.showFrame) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  watch: {
  
  },
  methods: {
    getCssClasses() {
      var classes = []
      if (this.isSelected) {
        classes.push('isSelected')
      }
      if (this.widgetSettings) {
        //if (this.widgetSettings.showFrame) {
          classes.push('pa-4')
        //}
      }
      return classes
    },
    setSelectedDate(data) {
      this.$emit('setSelectedDate', data)
    },
    setHeight(data) {
      this.$emit('setHeight', data)
    },
    removeWidget(data) {
      this.$emit('removeWidget', data)
    },
    setSelectedWidget() {
      if (this.editmode) {
        this.$emit('setSelectedWidget')
      }
      
    },
    setWidgetData(data) {
      this.$emit('setWidgetData', data)
    }
    
  }
}
</script>
<style>
.isSelected {
  outline: 2px dashed red;
  outline-offset: 2px;
}
.v-card--link:focus {
  background: transparent;
}
.v-card--link:focus::before {
  opacity: 0;
}
</style>
