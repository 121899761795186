<template>
  <v-card elevation="6" class="fill-height overflow-auto">
    <v-btn
      v-if="editmode"
      @click="$emit('removeWidget')"
      icon
      absolute
      top right
    >
      <v-icon 
        small
      >
        mdi-minus-circle
      </v-icon>
    </v-btn>
    <v-card-title :class="editmode ? 'dragHandle':''">
      Einstellungen für Gradtagzahlen
    </v-card-title>
    <v-card-text >
      <v-select
        v-model="value.heatingLimitTemperature"
        hint="Ab dieser Außentemperatur wird geheizt"
        :items=[8,9,10,11,12,13,14,15,16]
        item-text="text"
        item-value="value"
        persistent-hint
        return-object
        @change="emitData()"
      >
        <template v-slot:label>
          <h3>Heizgrenztemperatur</h3>
        </template>
        <template v-slot:append>
          °C
        </template>
      </v-select>

      <v-select
        v-model="value.indoorTemperature"
        hint="Gewünschte Innenraumtemperatur"
        :items=[17,18,19,20,21,22]
        item-text="text"
        item-value="value"
        label="Innentemperatur"
        persistent-hint
        return-object
        @change="emitData()"
        class="mt-3"
      >
        <template v-slot:label>
          <h3>Innentemperatur</h3>
        </template>
        <template v-slot:append>
          °C
        </template>
      </v-select>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      
    }),
    props: [
      'value',
      'editmode'
    ],
    computed: {
      
    },
    methods: {
      emitData() {
        this.$emit('input', this.value)
      }
    }
    
  }

</script>
<style>

</style>
