<template>
   <v-card class="fill-height overflow-auto">
    <v-card-title>Boards verwalten</v-card-title>
    
    <v-card-text >
      <v-simple-table class="pa-0">
        <template v-slot:default>
          <tbody>
            <tr
              v-for="board in boards"
              :key="board.id"
            >
              <td class="pa-0 px-1">
                <v-text-field
                  v-model="board.title"
                  dense
                  solo
                  hide-details
                  flat
                ></v-text-field>
              </td>
              <td class="pa-0 text-right">
                <v-btn @click="saveBoard(board.id)" x-small >
                  <v-icon small color="primary">mdi-tray-arrow-down</v-icon>
                </v-btn>
              </td>
              <td class="pa-0 text-right px-1">
                <v-btn @click="deleteBoard(board)" x-small v-if="boards.length > 1">
                  <v-icon small color="red">mdi-minus-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions class="mb-2">
      <v-btn color="primary" @click="openImportDialog()">
        <v-icon left>
          mdi-tray-arrow-up
        </v-icon>
        Importieren
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="resetBoards()">
        <v-icon left>
          mdi-minus-circle
        </v-icon>
        Zurücksetzen
      </v-btn>
    </v-card-actions>
    <input 
      ref="uploader" 
      class="d-none" 
      type="file" 
      @change="importBoard"
    >
  </v-card>
</template>

<script>
  export default {
    data () {
      return {
        boards: this.value
      }
    },
    props: [
      'value'
    ],
    computed: {

    },
    methods: {
      saveBoard(id) {
        var board = this.boards.filter(function(board) {
          if (board.id == id) {
            return board
          }
        })
        var boardJson = JSON.stringify(board);
        var filename = board[0].title + '_' + board[0].id + '.json'
        var blob = new Blob([boardJson], { type: 'text/json;charset=utf-8;' });
        if (navigator.msSaveBlob) { 
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { 
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
      },
      openImportDialog() {
          window.addEventListener('focus', () => {
          }, { once: true })
          
          // Trigger click on the FileInput
          this.$refs.uploader.click()
      },
      importBoard(e) {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('loadend', (e) => {
          const newBoardJson = e.srcElement.result
          const newBoard = JSON.parse(newBoardJson)
          newBoard[0].id = new Date()
          this.boards.push(newBoard[0])
          // check if board already exists
          /*
          var board = this.boards.find(board => board.id == newBoard[0].id)
          if (board) {
            board = newBoard
          } else {
            
          }*/
          
          this.emitData()
        })

        // Start reading the blob as text.
        reader.readAsText(file)

      },
      deleteBoard(board) {
        this.$confirm('Board ' + board.title + ' wirklich löschen?', {buttonTrueText: 'ja', buttonFalseText: 'nein'}).then(res => {
          if (res) {
            this.boards = this.boards.filter(item => item.id != board.id)
            this.emitData()
          }
        })
      },
      emitData() {
        this.$emit('input', this.boards)
      },
      resetBoards() {
        this.$confirm('Alle neu erstellten Boards sowie alle an den Boards vorgenommenen Änderungen werden gelöscht!', {buttonTrueText: 'ja', buttonFalseText: 'nein'}).then(res => {
          if (res) {
            localStorage.removeItem('climateboards')
            window.location.reload()
          }
        })
      }
    }
    
  }

</script>
<style>

</style>
