<template>
    <div class="fill-height">
      <div v-if="!startDate && !endDate" class="d-flex flex-column fill-height justify-center">
        <div class="text-h5 pa-0 text-center">
          {{widgetSettings.resolution.text}}
        </div>
        <div class="text-h6 pa-0 text-center">
         Keine Daten
        </div>
      </div>
      <v-chart 
        v-else
        :option="getChartSettings()" 
        :init-options="initOptions" 
        :updateOptions="updateOptions"
        @selectchanged = "selectchanged"
        autoresize 
      />
    </div>
</template>

<script>
  import VChart from "vue-echarts"
  import 'echarts/i18n/langDE'
  import { DateTime } from "luxon"

  export default {
    components: {
      VChart
    },
    name: 'Chart',

    data: () => ({
      xAxis: [],
      initOptions: {
        locale: 'de',
        height: 'auto'
      },
      updateOptions: {
        notMerge: true
      },  
      noData: false
    }),
    props: [
      'climateDataDaily',
      'climateDataMonthly',
      'climateDataYearly',
      'id',
      'boardSettings',
      'config',
      'selectedDate',
      'selectedBoard',
      'selectedWidget',
      'widgetSettings',
      'editmode'
    ],
    mounted: function() {
   
    },
    computed: {
      startDate() {
        if (this.widgetSettings.resolution.value == 'day') {
          return DateTime.fromISO(this.selectedDate).startOf('month').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'month') {
          return DateTime.fromISO(this.selectedDate).startOf('year').toFormat('yyyy-MM')
        } else if (this.widgetSettings.resolution.value == 'year') {
          return DateTime.fromISO(this.config.startDateClimateData).startOf('year').toFormat('yyyy')
        } else {
          return this.boardSettings[this.widgetSettings.resolution.value].period[0]
        }
      },
      endDate() {
        if (this.widgetSettings.resolution.value == 'day') {
          return DateTime.fromISO(this.selectedDate).endOf('month').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'month') {
          return DateTime.fromISO(this.selectedDate).endOf('year').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'year') {
          return DateTime.now().toFormat('yyyy')
        } else {
          return this.boardSettings[this.widgetSettings.resolution.value].period[1]
        }
      },
      displayDate() {
        if (this.widgetSettings.resolution.value == 'day') {
          return DateTime.fromISO(this.selectedDate).toFormat('MM/yyyy')
        } else if (this.widgetSettings.resolution.value == 'month') {
          return DateTime.fromISO(this.selectedDate).toFormat('yyyy')
        } else if (this.widgetSettings.resolution.value == 'year') {
          return DateTime.fromISO(this.startDate).toFormat('yyyy') + '-' + this.endDate
        } else {
          return this.boardSettings[this.widgetSettings.resolution.value].name
        }
      },
      climateData() {
        var climateData
        if (this.widgetSettings.resolution.value == 'year') {
          climateData = this.climateDataYearly
        } else if (this.widgetSettings.resolution.value == 'month') {
          climateData = this.climateDataMonthly
        } else {
          climateData = this.climateDataDaily
        }
        return climateData
      },
      dateFormat() {
        var dateFormat
        if (this.widgetSettings.resolution.value == 'year') {
          dateFormat = 'yyyy'
        } else if (this.widgetSettings.resolution.value == 'month') {
          dateFormat = 'MM/yyyy'
        } else {
          dateFormat = 'dd.MM'
        }
        return dateFormat
      }
  
    },
    methods: {
      getChartSettings: function() {
        var markLineDate
        if (this.widgetSettings.resolution.value == 'year') {
          markLineDate = DateTime.fromISO(this.selectedDate).toFormat('yyyy')
        } else if (this.widgetSettings.resolution.value == 'month') {
          markLineDate = DateTime.fromISO(this.selectedDate).toFormat('MM/yyyy')
        } else {
          markLineDate = DateTime.fromISO(this.selectedDate).toFormat('dd.MM')
        }

        var series = []
        var scalas = []
        var i=1
        var startDate 
        var endDate
        var displayDate 
   

        // first scala is for temperature
        scalas.push({
          type: 'value',
          show: true,
          alignTicks: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true
          },
          scale: true,
          position: 'left',
          axisLabel: {
            formatter: '{value} °C' 
          }
        })

        var xAxis = this.getXaxis(this.startDate, this.endDate)

        this.widgetSettings.charts.forEach(function(chart) {

          startDate = this.startDate
          endDate = this.endDate
          displayDate = this.displayDate

          if (chart.lastYear && (this.widgetSettings.resolution.value == 'day' || this.widgetSettings.resolution.value == 'month')) {
            startDate = DateTime.fromISO(startDate).plus({year: -1}).toFormat('yyyy-MM-dd')
            endDate = DateTime.fromISO(endDate).plus({year: -1}).toFormat('yyyy-MM-dd')
            if (this.widgetSettings.resolution.value == 'day') {
              displayDate = DateTime.fromISO(this.selectedDate).plus({year: -1}).toFormat('MM/yyyy')
            } else if (this.widgetSettings.resolution.value == 'month') {
              displayDate = DateTime.fromISO(this.selectedDate).plus({year: -1}).toFormat('yyyy')
            }
          } 
          var data = this.getYaxis(startDate, endDate, chart.parameter.value)

          var yAxisIndex = i
          // if parameter is temperature related use always first scala
          if (chart.parameter.value.startsWith('temp')) {
            yAxisIndex = 0
          }

          var markline = {}
          if (i == 1) {
            markline = {
              symbol: ['none', 'none'],
              label: { show: false },
              lineStyle: { 
                color: '#000',
                width: 2
              },
              animationDuration: 500,
              data: [{ xAxis: markLineDate }]
            }
          }

          series.push({
            type: chart.chart.value,
            data: data,
            yAxisIndex: yAxisIndex,
            name: chart.parameter.text + ' ' + displayDate,
            itemStyle: {
              color: chart.color
            },
            selectedMode: 'single',
            select: {
              itemStyle: {
                borderColor: 'transparent'
              }  
            },
            label: {
              show: chart.showLabel,
              position: 'top'
            },
            markLine: markline
          })

          var showScale = true
          if (chart.scale.value == 'none') {
            showScale = false
          }
          
          scalas.push({
            type: 'value',
            show: showScale,
            alignTicks: false,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true
            },
            scale: true,
            position: chart.scale.value,
            axisLabel: {
              formatter: '{value} ' + chart.parameter.unit
            }
          })
          i++
        }, this)
      
        var chartSettings = {
          legend: {
            top: 0,
            right: 0,
            show: true,
            textStyle: {
              fontFamily: '"Roboto Slab", Helvetica, Arial, sans-serif'
            },
          },
          grid: {
            left: 0,
            right: 5,
            top: 30,
            bottom: 5,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              label: {
                formatter: function (params) {
                  return params.value
                }
              }
            }
          },
          yAxis: scalas,
          xAxis: { 
            type: 'category',
            data: xAxis,
            alignTicks: true,
            axisLine: {
              onZero: false
            }
       
          },
          series: series
        }
       
        return chartSettings
      },
      selectchanged: function(data) {
        if (data.selected.length > 0) {
          var dataIndex = data.selected[0].dataIndex[0]

          var year = DateTime.fromISO(this.selectedDate).toFormat('yyyy')
          var month = DateTime.fromISO(this.selectedDate).toFormat('MM')
          var day = DateTime.fromISO(this.selectedDate).toFormat('dd')
          var selectedDate

          if (this.widgetSettings.resolution.value == 'year') {
            var selectedYear = parseInt(DateTime.fromISO(this.startDate).toFormat('yyyy')) + dataIndex
            selectedDate = selectedYear + '-' + month + '-' + day
          } else if (this.widgetSettings.resolution.value == 'month') {
            var selectedMonth = parseInt(DateTime.fromISO(this.startDate).toFormat('MM')) + dataIndex
            if (selectedMonth < 10) {
              selectedMonth = '0' + selectedMonth
            }
            selectedDate = year + '-' + selectedMonth + '-' + day
          } else {
            var selectedDay = parseInt(DateTime.fromISO(this.startDate).toFormat('dd')) + dataIndex
            if (selectedDay < 10) {
              selectedDay = '0' + selectedDay
            }
            selectedDate = year + '-' + month + '-' + selectedDay
          }
          this.$emit('setSelectedDate', selectedDate)
        }
      },  
      getXaxis(startDate, endDate) {
        var data = this.climateData.filter(function(item) {
          if (item.date >= startDate && item.date <= endDate) {
            return true
          }
        }, this).map(function(item) {
          return DateTime.fromISO(item.date).toFormat(this.dateFormat)
        }, this)
        /*
        for (var i=0; i <= 365 - 1; i++) {
          var date
          if (this.widgetSettings.resolution.value == 'day') {
            date = DateTime.now().plus({'days': i}).toFormat('yyyy-MM-dd')
          } else if (this.widgetSettings.resolution.value == 'month') {
            date = DateTime.now().plus({'months': i}).toFormat('yyyy-MM')
          } else {
            date = DateTime.now().plus({'year': i}).toFormat('yyyy')
          }
          data.push([date, null])
        }*/
        return data
      },
      getYaxis(startDate, endDate, parameter) {
        var data = this.climateData.filter(function(item) {
          if (item.date >= startDate && item.date <= endDate) {
            return true
          }
        }, this).map(function(item) {
          var roundedValue
          if (parameter == 'energy_consumption_costs') {
            roundedValue = (Math.round(item[parameter] * 100) / 100).toFixed(2)
          } else {
            roundedValue = Math.round(item[parameter] * 10) / 10
          }
          return roundedValue
          
        }, this)
        return data
      }
    }
  }
</script>
<style>
.v-color-picker__swatches > div {
    justify-content: left;
    padding: 0;
}
</style>
