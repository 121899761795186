<template>
  <div>
    Text:
    <tiptap-vuetify
      v-model="value.text"
      :extensions="extensions"
    />
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
    ></v-switch>   
  </div>
</template>

<script>
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  History
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      
      Bold,
      Italic,
      Underline,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Link,
      OrderedList,
      BulletList,
      ListItem,
      History
    ]
  }),
  props: [
    'value'
  ],
  watch: {
    value: {
      handler() {
        this.$emit('input', this.value)
      },
      deep: true
    }
  }
  
}

</script>
<style>

</style>
