<template>
   <v-chart 
    :option="getHeatmap()" 
    :init-options="initOptions" 
    autoresize 
  />
</template>

<script>
  import VChart from "vue-echarts"
  import 'echarts/i18n/langDE'
  import { DateTime } from "luxon"

  export default {
    components: {
      VChart
    },
    name: 'Heatmap',

    data: () => ({
      
      initOptions: {
        locale: 'de',
        height: 'auto'
      }
    }),
    props: [
      'climateDataDaily',
      'climateDataMonthly',
      'climateDataYearly',
      'selectedDate',
      'id',
      'widgetSettings',
    ],
    computed: {
      year: function() {
        return DateTime.fromISO(this.selectedDate).toFormat('yyyy')
      }
    },
    methods: {
      getHeatmap: function() {
        var minValue = 100
        var maxValue = -100
        var chartData =  this.climateDataDaily.map(function(item) {
          var roundedValue = Math.round(item[this.widgetSettings.parameter.value] * 10) / 10
          if (roundedValue > maxValue) {
            maxValue = roundedValue
          }
          if (roundedValue < minValue) {
            minValue = roundedValue
          }
          return [item.date, roundedValue]
        }, this)
        var visualMap = {
          min: minValue,
          max: maxValue,
          calculable: true,
          orient: 'vertical',
          inRange: {
              color: [this.widgetSettings.color1, this.widgetSettings.color2, this.widgetSettings.color3],
          },
          show: false
        }
        var options = {
          title: {
            show: true,
            text: this.widgetSettings.parameter.text + ' ' + this.year,
            textStyle: {
              fontFamily: '"Roboto Slab", Helvetica, Arial, sans-serif',
              fontSize: 12
            },
            left: 'left'
          },
          tooltip: {},
          visualMap: visualMap,
          grid: {
            left: 0,
            right: 5,
            top: 45,
            bottom: 0,
            containLabel: true
          },
          calendar: {
            top: 45,
            left: 30,
            right: 0,
            bottom: 0,
            range: this.year,
            cellSize: ['auto', 'auto'],
            orient: 'vertical',
            itemStyle: {
              borderWidth: 0.5
            },
            yearLabel: { show: false },
            dayLabel: {
              firstDay: 1 // start on Monday
            }
          },
          series: {
            type: 'heatmap',
            universalTransition: {
              enabled: true
            },
            coordinateSystem: 'calendar',
            data: chartData
          }
        }
        return options
      }
    }
  }
</script>
<style>
.v-color-picker__swatches > div {

    justify-content: left;
    padding: 0;
}
</style>
