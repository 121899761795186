import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import "echarts"
import ECharts from 'vue-echarts'
import VuetifyConfirm from 'vuetify-confirm'
import "@fontsource/roboto-slab"
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'


Vue.config.productionTip = false

Vue.use(vuetify)
Vue.use(VuetifyConfirm, {
  vuetify
})
Vue.use(TiptapVuetifyPlugin, {
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  vuetify,
  iconsGroup: 'mdi'
})

new Vue({
  vuetify,
  axios,
  ECharts,
  VuetifyConfirm,
  render: h => h(App)
}).$mount('#app')
