<template>
  <v-data-table
    :headers="headers"
    :items="filterClimateData()"
    :items-per-page=-1
    fixed-header
    hide-default-footer
    dense
    sort-by="date"
    sort-desc
    height="100%"
    single-select
    item-key="date"
    class="overflow-hidden"
    @click:row="selectRow"
    :value="getSelectedItem()"
  >
    <template v-slot:item.date="{ item }">
      <span>{{ formatDate(item.date) }}</span>
    </template>
    <template v-slot:item.temperature_air_mean_200="{ item }">
      <span>{{ roundValue(item.temperature_air_mean_200) }} °C</span>
    </template>
    <template v-slot:item.temperature_air_min_200="{ item }">
      <span>{{ roundValue(item.temperature_air_min_200) }} °C</span>
    </template>
    <template v-slot:item.temperature_air_max_200="{ item }">
      <span>{{ roundValue(item.temperature_air_max_200) }} °C</span>
    </template>
    <template v-slot:item.heating_degree="{ item }">
      <span>{{ roundValue(item.heating_degree) }} kD</span>
    </template>
    <template v-slot:item.precipitation_height="{ item }">
      <span>{{ roundValue(item.precipitation_height) }} l</span>
    </template>
    <template v-slot:item.sunshine_duration="{ item }">
      <span>{{ roundValue(item.sunshine_duration) }} h</span>
    </template>
  </v-data-table>

</template>

<script>
  import { DateTime } from "luxon"

  export default {
    name: 'Datatable',

    data: () => ({

    }),
    props: [
      'climateDataDaily',
      'climateDataMonthly',
      'climateDataYearly',
      'id',
      'selectedDate',
      'widgetSettings',
      'boardSettings',
      'config'
    ],
    computed: {
      headers: function() {
        var headers = this.widgetSettings.parameter
        if (headers.length > 0) {
          if (headers[0].value != 'date') {
            headers.unshift({
              text: 'Datum',
              value: 'date'
            })
          }
        }
        return headers
      },
      startDate() {
        if (this.widgetSettings.resolution.value == 'day') {
          return DateTime.fromISO(this.selectedDate).startOf('month').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'month') {
          return DateTime.fromISO(this.selectedDate).startOf('year').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'year') {
          return this.config.startDateClimateData
        } else {
          return this.boardSettings[this.widgetSettings.resolution.value].period[0]
        }
      },
      endDate() {
        if (this.widgetSettings.resolution.value == 'day') {
          return DateTime.fromISO(this.selectedDate).endOf('month').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'month') {
          return DateTime.fromISO(this.selectedDate).endOf('year').toFormat('yyyy-MM-dd')
        } else if (this.widgetSettings.resolution.value == 'year') {
          return DateTime.now().toFormat('yyyy')
        } else {
          return this.boardSettings[this.widgetSettings.resolution.value].period[1]
        }
      }
    },
    methods: {
      formatDate: function(date) {
        var returndate = DateTime.fromISO(date).toFormat('dd.MM.yyyy')
        if (this.widgetSettings.resolution.value == 'month') {
          returndate = DateTime.fromISO(date).toFormat('MM/yyyy')
        }
        if (this.widgetSettings.resolution.value == 'year') {
          returndate = DateTime.fromISO(date).toFormat('yyyy')
        }
        return returndate
      },
      roundValue: function(value) {
        return  Math.round(value * 10) / 10
      },
      addUnit: function(item) {
        for (const [key, value] of Object.entries(item)) {
          if (key == 'date') {
              item[key] = DateTime.fromISO(value).toFormat('dd.MM.yyyy')
          } else {
            item[key] = Math.round(value * 10) / 10
          }
        }
        return item
      },
      filterClimateData: function() {
        var climateData = this.climateDataDaily
        if (this.widgetSettings.resolution.value == 'month') {
          climateData = this.climateDataMonthly
        }
        if (this.widgetSettings.resolution.value == 'year') {
          climateData = this.climateDataYearly
        }
        var data = climateData.filter(function (item) {
          if (item.date >= this.startDate && item.date <= this.endDate) {
            return item
          }
        }, this)
        return data
      },
      selectRow(item, row) {
        row.select(true)

        var selectedDate
        var month = DateTime.fromISO(this.selectedDate).toFormat('MM')
        var day = DateTime.fromISO(this.selectedDate).toFormat('dd')
        if (this.widgetSettings.resolution.value == 'year') {
          selectedDate = item.date + '-' + month + '-' + day
        } else if (this.widgetSettings.resolution.value == 'month') {
          selectedDate = item.date + '-' + day
        } else {
          selectedDate = item.date
        }
        this.$emit('setSelectedDate', selectedDate)
      },
      getSelectedItem() {
        var returndate = DateTime.fromISO(this.selectedDate).toFormat('yyyy-MM-dd')
        if (this.widgetSettings.resolution.value == 'month') {
          returndate = DateTime.fromISO(this.selectedDate).toFormat('yyyy-MM')
        }
        if (this.widgetSettings.resolution.value == 'year') {
          returndate = DateTime.fromISO(this.selectedDate).toFormat('yyyy')
        }
        return [{
          date: returndate
        }]
      }
    }
  }
</script>
<style>

.v-color-picker__swatches > div {

    justify-content: left;
    padding: 0;
}
</style>
