//import { DateTime } from "luxon"
export const getHeatingDegreesPeriod = function(climateDataDaily, heatingLimitTemperature, indoorTemperature, startDate, endDate, consumption, period) {
  climateDataDaily.sort(function(a, b){
    const date1 = new Date(a.date)
    const date2 = new Date(b.date)
    return date1 - date2;
  })

  var heating_degree_sum = 0
  var items = climateDataDaily.filter(item => (new Date(item.date) >= new Date(startDate) && new Date(item.date) <= new Date(endDate)))
  
  if (items) {
    items.map(function(item) {
      var heatingDeg
      var tempCelsius = item['temperature_air_mean_200']
      if (tempCelsius < heatingLimitTemperature) {
        heatingDeg = indoorTemperature - tempCelsius
      } else {
        heatingDeg = 0
      }
      heating_degree_sum += heatingDeg
      item['heating_degree_' + period] = heatingDeg
      
      return item
    })

    var energyPerGTZ = consumption / heating_degree_sum
    items.map(function(item) {
      var energyPerDay = item['heating_degree_' + period] * energyPerGTZ  
      item['energy_consumption_' + period] = Math.round(energyPerDay * 10)/10
      return item
    })
  }
  
  return {heating_degree_sum: Math.round(heating_degree_sum), energyPerGTZ: energyPerGTZ}
}

export const getConsumptionVariants = function(climateDataDaily, heatingLimitTemperature, indoorTemperature, startDate, endDate, energyPerGTZ, consumption) {
  var items = climateDataDaily.filter(item => (new Date(item.date) >= new Date(startDate) && new Date(item.date) <= new Date(endDate)))

  var heating_degree_sum = {}
  for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
    heating_degree_sum[i] = 0
  }

  if (items) {
    items.map(function(item) {
      var tempCelsius = item['temperature_air_mean_200']
      if (tempCelsius < heatingLimitTemperature) {
        for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
          heating_degree_sum[i] = heating_degree_sum[i] + (i - tempCelsius)
        }
      } 
    })
    var returnData = [] 
    for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
      var dataObj = {}
      dataObj.indoorTemperature = i
      dataObj.heating_degree_sum = Math.round(heating_degree_sum[i])
      dataObj.consumption = Math.round(energyPerGTZ * heating_degree_sum[i] * 10)/10
      dataObj.diff = Math.round((dataObj.consumption / consumption - 1) * 1000) / 10
      returnData.push(dataObj)
    }
    return returnData
  }  
}