<template>
  <div v-html="widgetSettings.text"></div>
</template>

<script>
  export default {
    data: () => ({

    }),
    props: [
      'id',
      'widgetSettings',
      'selectedBoard',
      'selectedWidget',
      'gridLayoutSettings',
      'editmode'
    ],
    computed: {
 
    },
    methods: {
      adaptHeight() {
        var editor = this.$refs['editor'].$el
        //var parentEl = this.$refs['editor'].$parent.$el
        //console.log(parentEl.paddin)
        if (editor) {
          var scrollHeight = editor.scrollHeight + 10
          //var clientHeight = editor.clientHeight
          
          //if (scrollHeight != clientHeight) {
            var oneRowHeight = this.gridLayoutSettings.rowHeight + this.gridLayoutSettings.margin[1]
            var newHeight = scrollHeight/oneRowHeight
            this.$emit('setHeight', {
              newHeight: newHeight,
              id: this.id
            })
          //}
        }
        
      }
    }
  }
</script>
<style>
.v-application p {
    margin-bottom: 6px;
}
</style>
