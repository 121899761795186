<template>
  <div>
    <v-select
      v-model="value.parameter"
      :items="paramsSelect"
      label="Parameter"
      return-object
    ></v-select>
    <div class="v-label theme--light d-flex align-center">
      <v-swatches
        v-model="value.color1"
        popover-x="right"
        swatches="text-advanced"
        :trigger-style="{ width: '40px', height: '26px' }"
        class="mr-2"
      ></v-swatches> 
      Farbe niedrigster Wert
    </div>
    <div class="v-label theme--light d-flex align-center my-4">
      <v-swatches
        v-model="value.color2"
        popover-x="right"
        swatches="text-advanced"
        :trigger-style="{ width: '40px', height: '26px' }"
        class="mr-2"
      ></v-swatches> 
      Zwischenfarbe
    </div>
    <div class="v-label theme--light d-flex align-center">
      <v-swatches
        v-model="value.color3"
        popover-x="right"
        swatches="text-advanced"
        :trigger-style="{ width: '40px', height: '26px' }"
        class="mr-2"
      ></v-swatches> 
      Farbe höchster Wert
    </div>
  
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
      class="mt-5 mb-0"
    ></v-switch>   
  </div>
</template>

<script>
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  export default {
    components: { VSwatches },
    data: () => ({
      paramsSelect: [{
        text: 'Durchschnittstemperatur', 
        value: 'temperature_air_mean_200',
        unit: '°C'
      },{
        text: 'Höchsttemperatur',
        value: 'temperature_air_max_200',
        unit: '°C'
      },{
        text: 'Tiefsttemperatur',
        value: 'temperature_air_min_200',
        unit: '°C'
      },{
        text: 'Gradtagzahl',
        value: 'heating_degree',
        unit: 'kD'
      },{
        text: 'Niederschlag',
        value: 'precipitation_height',
        unit: 'l'
      },{
        text: 'Sonnenstunden',
        value: 'sunshine_duration',
        unit: 'h'
      }],
    }),
    props: [
      'value'
    ],
    watch: {
      value: {
        handler() {
          this.$emit('input', this.value)
        },
        deep: true
      }
    }
    
  }

</script>
<style>

</style>
