<template>
  <div>
    <v-select
      v-model="value.sliderConfig"
      :items="sliderSelect"
      label="Slidereinstellung"
      return-object>
    </v-select>
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
    ></v-switch>
  </div>
</template>

<script>

export default {
  data: () => ({
    sliderSelect: [{
      text: 'Jahre',
      value: 'years'
    },{
      text: 'Monate',
      value: 'months'
    },{
      text: 'Heizgrenztemperatur',
      value: 'heatingLimitTemperature'
    },{
      text: 'Innentemperatur',
      value: 'indoorTemperature'
    }],
  }),
  props: [
    'value'
  ]
  
  
}

</script>
<style>

</style>
